import { useIntl } from 'react-intl'

import { ShipmentEnums } from '@b-stock/bstock-react/locale'
import type { ShippingAllowedShipmentTypesEnum } from '@b-stock/listing-api-client'

import CategoricalFilter from '../../shared/CategoricalFilter'
import {
  useFilterOptions,
  createCategoricalAppliedComponent,
} from '../../shared/filterUtils'
import type { AuctionFilter, FilterProps } from '../../types'

const toDisplayName = (option: string) => (
  <ShipmentEnums.ShipmentType.Component
    // We know the option will be an enum value in practice, even though
    // the API doesn't strictly enforce it. If it ever isn't, ListingEnums
    // should gracefully handle this and just output the raw option string.
    value={option as ShippingAllowedShipmentTypesEnum}
  />
)

const AllowedShipmentTypesFilter: AuctionFilter<Set<string>> = ({
  onChange,
  desktopScreen,
  items: itemsData,
  availableItems: availableItemsData,
  value,
}: FilterProps<Set<string>>) => {
  const intl = useIntl()
  const items = useFilterOptions(itemsData, toDisplayName)
  const availableItems = useFilterOptions(availableItemsData, toDisplayName)

  return (
    <CategoricalFilter
      items={items}
      availableItems={availableItems}
      selected={value}
      name={intl.formatMessage({ id: 'Filters.allowedShipmentTypes' })}
      onChange={onChange}
      desktopScreen={desktopScreen}
    />
  )
}

AllowedShipmentTypesFilter.getOwnProps = ({ state, data, updateFilters }) => {
  return {
    items: data?.allFilters['Allowed Shipment Types'] || {},
    availableItems: data?.availableFilters['Allowed Shipment Types'] || {},
    value: new Set(state.filters.allowedShipmentTypes),
    onChange: (value: Set<string>) =>
      updateFilters({ allowedShipmentTypes: [...value] }),
  }
}

AllowedShipmentTypesFilter.label = 'Filters.allowedShipmentTypes'

AllowedShipmentTypesFilter.Applied = createCategoricalAppliedComponent(
  'allowedShipmentTypes',
  AllowedShipmentTypesFilter.label,
  toDisplayName
)

export default AllowedShipmentTypesFilter
