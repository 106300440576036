import { useIntl } from 'react-intl'

import { ListingEnums } from '@b-stock/bstock-react/locale'
import type { ShippingTransportModeEnum } from '@b-stock/listing-api-client'

import CategoricalFilter from '../../shared/CategoricalFilter'
import {
  useFilterOptions,
  createCategoricalAppliedComponent,
} from '../../shared/filterUtils'
import type { AuctionFilter, FilterProps } from '../../types'

const toDisplayName = (option: string) => (
  <ListingEnums.TransportMode.Component
    // We know the option will be an enum value in practice, even though
    // the API doesn't strictly enforce it. If it ever isn't, ListingEnums
    // should gracefully handle this and just output the raw option string.
    value={option as ShippingTransportModeEnum}
  />
)

const TransportModeFilter: AuctionFilter<Set<string>> = ({
  onChange,
  desktopScreen,
  items: itemsData,
  availableItems: availableItemsData,
  value,
}: FilterProps<Set<string>>) => {
  const intl = useIntl()
  const items = useFilterOptions(itemsData, toDisplayName)
  const availableItems = useFilterOptions(availableItemsData, toDisplayName)

  return (
    <CategoricalFilter
      items={items}
      availableItems={availableItems}
      selected={value}
      name={intl.formatMessage({
        id: 'Filters.transportMode',
      })}
      onChange={onChange}
      desktopScreen={desktopScreen}
    />
  )
}

TransportModeFilter.getOwnProps = ({ state, data, updateFilters }) => {
  return {
    items: data?.allFilters['Transport Mode'] || {},
    availableItems: data?.availableFilters['Transport Mode'] || {},
    value: new Set(state.filters.transportMode),
    onChange: (value: Set<string>) =>
      updateFilters({ transportMode: [...value] }),
  }
}

TransportModeFilter.label = 'Filters.transportMode'

TransportModeFilter.Applied = createCategoricalAppliedComponent(
  'transportMode',
  TransportModeFilter.label,
  toDisplayName
)

export default TransportModeFilter
